import "./App.css";
import { useState } from "react";
import ReactGA from "react-ga4";
import GitHubLogo from "./github-logo.svg";

ReactGA.initialize("G-VBDEDXY8Z7");
ReactGA.send({ hitType: "pageview", page: "/" });

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>EGEMEN GÜLKILIK</h1>
        <h2>aleyk. slm.</h2>
        <h3>ve rhmtl</h3>
        <div>
          <code>let a = 1;</code>
        </div>
        <a href="https://github.com/cicimen" className="App-link">
          <img src={GitHubLogo} alt="GitHub Logo" className="github-logo" />
          GitHub
        </a>
      </header>
      <div className="App-body">
        <TicTacToe />
      </div>
    </div>
  );
}

function TicTacToe() {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [playerX, setPlayerX] = useState(true);
  const winner = calculateWinner(board);
  const isDraw = board.every((square) => square !== null) && !winner;

  function handleClick(index: number) {
    if (board[index] || winner || isDraw) return;

    const newBoard = board.slice();
    newBoard[index] = playerX ? "X" : "O";
    setBoard(newBoard);
    setPlayerX(!playerX);
  }

  function renderSquare(index: number) {
    return (
      <div className="Game-square" onClick={() => handleClick(index)}>
        {board[index]}
      </div>
    );
  }

  function restartGame() {
    setBoard(Array(9).fill(null));
    setPlayerX(true);
  }

  return (
    <div>
      <div className="Game-board">
        {board.map((_, index) => renderSquare(index))}
      </div>
      {winner && <div className="winner-message">Winner: {winner}</div>}
      {isDraw && !winner && <div className="winner-message">It's a draw!</div>}
      {(winner || isDraw) && (
        <button className="restart-button" onClick={restartGame}>
          Restart Game
        </button>
      )}
    </div>
  );
}

function calculateWinner(board: any[]) {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];

  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (board[a] && board[a] === board[b] && board[a] === board[c]) {
      return board[a];
    }
  }

  return null;
}

export default App;
